.link {
    text-decoration: none;
    color: #6c757d;
    font-weight: bold;
}

.link:hover {
    color: #0d6efd;
}

.form-check-input {
    border: 1px solid #606060;
}

.dropdown-item:active {
    background-color: transparent !important;
}

.dropdown-item.active {
    background-color: #505050;
}

/* ==============================
# Images
============================== */
.gallery img {
    height: 20vh;
    object-fit: cover;
    object-position: top;
}

.gallery .dropdown-toggle::after {
    display: none !important;
}